import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Loader from "react-loaders";

import './index.scss';
import LogoTitle from '../../assets/images/logo-a.png';
import AnimatedLetters from '../AnimatedLetters';
import Logo from "./Logo";

const Home = () => {
    const [letterClass, setLetterClass] = useState('text-animate');

    const nameArray = ['k', 's', 'h', 'i', 't', 't', 'h'];
    const jobArray = ['S', 'o', 'f', 't', 'w', 'a', 'r', 'e', ' ', 'D', 'e', 'v', 'e', 'l', 'o', 'p', 'e', 'r'];

    useEffect(() => {
        const timer = setTimeout(() => {
            setLetterClass('text-animate-hover');
        }, 5000);
        return () => clearTimeout();
    }, []);

    return (
        <>
            <div className="container home-page">
                <div className="text-zone">
                    <h1>
                        <span className={letterClass}>H</span>
                        <span className={`${letterClass} _12`}>i,</span>

                        <br />

                        <span className={`${letterClass} _13`}>I</span>
                        <span className={`${letterClass} _14`}>'m</span>

                        <img src={LogoTitle} alt="Logo" />

                        <AnimatedLetters letterClass={letterClass} strArray={nameArray} index={15} />
                        <br />
                        <AnimatedLetters letterClass={letterClass} strArray={jobArray} index={22} />
                    </h1>
                    <h2>Django / React Native / .NET</h2>
                    <Link className="flat-button" to="/contact">CONTACT ME</Link>
                </div>
                <Logo />
            </div>

            <Loader type="pacman" />
        </>
    );
};

export default Home;
