import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faClose, faEnvelope, faFileAlt, faHome, faUser } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { useState } from 'react';

import './index.scss';
import LogoA from '../../assets/images/logo-a.png';
import LogoSubtitle from '../../assets/images/logo-name.png';

const Sidebar = () => {
    const [showNav, setShowNav] = useState(false);

    return (
        <div className="nav-bar">
            <Link className="logo" to="/">
                <img src={LogoA} alt="logo" />
                <img className="sub-logo" src={LogoSubtitle} alt="logo" />
            </Link>

            <nav className={showNav ? "mobile-show" : ""}>
                <NavLink exact="true" activeClassname="active" className="home-link" to={"/"} onClick={() => setShowNav(false)}>
                    <FontAwesomeIcon icon={faHome} color="#4d4d4e" />
                </NavLink>
                <NavLink exact="true" activeClassname="active" className="about-link" to={"/about"} onClick={() => setShowNav(false)}>
                    <FontAwesomeIcon icon={faUser} color="#4d4d4e" />
                </NavLink>
                <NavLink exact="true" activeClassname="active" className="contact-link" to={"/contact"} onClick={() => setShowNav(false)}>
                    <FontAwesomeIcon icon={faEnvelope} color="#4d4d4e" />
                </NavLink>
                <NavLink to="Akshitth's Resume.pdf" target="_blank" className="resume-link" download onClick={() => setShowNav(false)}>
                    <FontAwesomeIcon icon={faFileAlt} color="#4d4d4e" />
                </NavLink>
                <FontAwesomeIcon icon={faClose} color="#ffd700" size="3x" className="close-icon" onClick={() => setShowNav(false)} />
            </nav>

            <ul>
                <li>
                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/akshitthr/" >
                        <FontAwesomeIcon icon={faLinkedin} color="#4d4d4e" />
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noreferrer" href="https://github.com/akshitthr" >
                        <FontAwesomeIcon icon={faGithub} color="#4d4d4e" />
                    </a>
                </li>
            </ul>

            <FontAwesomeIcon onClick={() => setShowNav(true)} icon={faBars} color="#ffd700" size="3x" className="hamburger-icon" />
        </div>
    );
};

export default Sidebar;
