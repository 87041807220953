import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAws, faGitAlt, faJsSquare, faPython, faReact, faUnity } from '@fortawesome/free-brands-svg-icons';
import Loader from 'react-loaders';

import './index.scss';
import AnimatedLetters from '../AnimatedLetters';

const About = () => {
    const [letterClass, setLetterClass] = useState('text-animate');

    useEffect(() => {
        const timer = setTimeout(() => {
            setLetterClass('text-animate-hover');
        }, 3000);
        return () => clearTimeout();
    }, []);

    return (
        <>
            <div className="container about-page">
                <div className="text-zone">
                    <h1>
                        <AnimatedLetters letterClass={letterClass} strArray={['A', 'b', 'o', 'u', 't', ' ', 'm', 'e']} index={15} />
                    </h1>
                    <p>
                        I am a passionate and ambitious self-taught developer who has learned programming
                        through a hands-on method by developing practical applications. I've worked on various
                        projects building Web, Mobile and Desktop Applications.
                    </p>
                    <p>
                        Currently looking for a role in the software field where I can
                        put my coding skills to use developing creative projects and optimal code.
                    </p>
                    <p>
                        I'm a pragmatic, naturally curious person who loves to solve problems and
                        constantly strives towards learning new things and improving myself.
                    </p>
                </div>

                <div className="stage-cube-cont">
                    <div className="cubespinner">
                        <div className="face1">
                            <FontAwesomeIcon icon={faPython} color="#4B8BBE" />
                        </div>
                        <div className="face2">
                            <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />
                        </div>
                        <div className="face3">
                            <FontAwesomeIcon icon={faAws} color="#FFF" />
                        </div>
                        <div className="face4">
                            <FontAwesomeIcon icon={faUnity} color="#626262" />
                        </div>
                        <div className="face5">
                            <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
                        </div>
                        <div className="face6">
                            <FontAwesomeIcon icon={faGitAlt} color="#EC4D28" />
                        </div>
                    </div>
                </div>
            </div>
            <Loader type="pacman" />
        </>
    );
};

export default About;
