import { useEffect, useRef } from 'react';
// import { gsap } from 'gsap-trial';
// import { DrawSVGPlugin } from 'gsap-trial/DrawSVGPlugin';

import './index.scss';
import LogoA from '../../../assets/images/logo-a.png';

const Logo = () => {
    const bgRef = useRef();
    const outlineLogoRef = useRef();
    const solidLogoRef = useRef();

    // useEffect(() => {
    //     gsap.registerPlugin(DrawSVGPlugin);

    //     gsap
    //     .timeline()
    //     .to(bgRef.current, {
    //         duration: 1,
    //         opacity: 1
    //     })
    //     .from(outlineLogoRef.current, {
    //         drawSVG: 0,
    //         duration: 20
    //     });

    //     gsap.fromTo(solidLogoRef.current, {
    //         opacity: 0
    //     },
    //     {
    //         opacity: 1,
    //         delay: 5.5,
    //         duration: 4
    //     });
    // }, []);
    
    return (
        <div className="logo-container" ref={bgRef}>
            <img ref={solidLogoRef} className="solid-logo" src={LogoA} alt="A" />

            <svg width="559" height="727" version="1.0" viewBox="0 0 419.25 545.25" xmlns="http://www.w3.org/2000/svg" zoomAndPan="magnify">
                <g className="svg-container" fill="none" stroke="none" transform="translate(6.5679 514.27)">
                    <path ref={outlineLogoRef} d="m246.02-186.39c-6.625-22.164-13.137-44.664-19.531-67.5-6.3984-22.844-12.906-45.688-19.531-68.531l-38.719 136.03zm24.328 85.656h-126.78l-35.969 126.09h-80.531l-20.203-20.219h-2.4062l152.83-509.17h74.688l0.6875 2.4062 19.875 19.875 150.77 507.11h-80.875l-20.203-20.219h-1.7188zm-77.094-283.38h2.0625l57.562 202.84h-117.88zm111.02 384.11h72.297l-148.36-498.89h-67.5l-149.73 498.89h72.297l35.969-126.09h149.06z"/>
                </g>
            </svg>
        </div>
    );
};

export default Logo;
